<template>
  <section>
     <!-- <div class="d-block d-lg-none" style="padding-top: 3.5rem;">

    </div>  -->
    <div class="presentation"  style="padding-top: 3.5rem;">
      <div class="position-relative div-without-padding col-lg-12 col-md-12">
        <a class="img-presentation" href="assets/TyC_BF2024_VF.pdf">
          <img class="img-presentation" rel="preload" :src="changeImagePresentation()" style="width:99.9%; height:99.9%; margin-left:6px; margin-top:16px"  />
        </a>
        <div class="position-absolute div-buttom col-lg-12 col-md-12">        
          <!---<button-credit
            class="btn-left"
            v-if="showButtonCredit && appOn ==='1'"
            routeNamePush="self-enrollment"
            :onClickCredit="registerLoan"
            description="Solicita tu crédito"
          /--->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getImgUrl } from "../../core/util/image";
import ButtonCredit from "../atoms/ButtonCredit.vue";
import {  mapActions } from "vuex";

export default {
  components: {
    ButtonCredit,
  },
  props: ["showButtonCredit"],
  data: () => ({
    windowWidth: window.innerWidth,
    appOn: process.env.VUE_APP_ON,
  }),
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  methods: {
    ...mapActions(["changeStep"],),
    registerLoan() {
      this.changeStep(1);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    changeImagePresentation() {
      if (this.windowWidth < 600) {
        return getImgUrl("COVER_LPT-Buen-fin.jpg", "jpg");
      } else {
        return getImgUrl("COVER_LPT-Buen-fin.jpg", "jpg");
      }
    },
  },
};
</script>

<style scoped>
.img-presentation {
  width: 100%;
  height: auto;
}
.div-buttom {
  bottom: 0.5rem;
}

.div-without-padding {
  padding: 0;
}

@media only screen and (max-width: 769px) {
  .div-buttom {
    bottom: 0.5rem;
  }
  .div-buttom .btn-left {
    margin-left: 4rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .div-buttom {
    bottom: 1rem;
  }
  .div-buttom .btn-left {
    margin-left: 5rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .div-buttom {
    bottom: 2rem;
  }
  .div-buttom .btn-left {
    margin-left: 9rem;
  }
}
@media only screen and (min-width: 1440px) {
  .div-buttom {
    bottom: 3rem;
  }
  .div-buttom .btn-left {
    margin-left: 15rem;
  }
}
</style>