<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact" style="">
    <div class="">
      <div class="row m-0 pb-1">
        <div class="col-lg-12">
          <div class="section-title p-0 card card-section-contact">
            <h2 class="mt-3 mb-3">
              Si tienes alguna duda, con gusto la resolvemos
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="card-section-contact-body" style="padding-bottom: 10px">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="info-box-contact mb-3">
              <i class="bx bx-support"></i>
              <h3>Nuestros días y horarios de atención</h3>
              <br />
              <p>Lunes a sábado de 8:00 a 20:00</p>
              <p>Domingo de 8:00 a 16:00</p>
              <br />
              <p>Redes Sociales</p>
              <p>Lunes a viernes de 9:00 a 18:00</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="info-box-contact" style="padding-bottom: 10px">
              <i class="bx bxl-whatsapp"></i>
              <h3>Dudas por whatsapp</h3>
              <!-- <p>55-8881-9900</p> -->
              <a href="https://c0m.app.link/e/lpt-ma">33-3549-0430</a>
            </div>
            <div class="info-box-contact" style="padding-bottom: 10px">
              <i class="bx bx-phone"></i>
              <h3>Dudas por teléfono</h3>
              <!-- <p>55-8881-9900</p> -->
              <a href="tel:55-8881-9900">55-8881-9900</a>
            </div>
            <br />

            <div class="info-box-contact mb-4" style="padding-top: 0px">
              <h3>¿Quieres saber más?</h3>
              <a href="#/faq-section">Preguntas frecuentes</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="info-box-contact mb-4">
              <i class="bx bx-envelope"></i>
              <h3>Escribenos a:</h3>
              <a href="mailto:lanapati@yastas.com">lanapati@yastas.com</a>
            </div>
          </div>

          <div class="container">
            <i class="bx bx-navigation"></i>
            <a href="assets/TyC_BF2024_VF.pdf"
              >Términos y Condiciones BF Noviembre 2024</a
            >
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FButton from "../atoms/FButton.vue";
import ButtonCredit from "../atoms/ButtonCredit.vue";

export default {
  components: {
    FButton,
    ButtonCredit
  },
  methods: {
    faq() {
      this.$router.push({ name: "faq-section" });
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

a {
  color: var(--color-text-link);
  text-decoration: underline;
  font-weight: 700;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #e96b56;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .info-box-contact {
  color: #444444;
  text-align: center;
  padding: 20px 0 30px 0;
}

.info-box-contact-horizontal {
  padding-top: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px !important;
}

.contact .info-box-contact i {
  font-size: 32px;
  color: #93c01f;
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box-contact h3 {
  font-size: 1rem;
  color: var(--color-primary-title-card);
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box-contact p {
  padding: 0;
  line-height: 24px;
  font-size: 0.88rem;
  margin-bottom: 0;
  color: var(--color-text-card-body);
  font-weight: 600;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #e96b56;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #e96b56;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #e6573f;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  border: 0;
}

.section-title h2 {
  font-size: 1.5rem;
  font-weight: 600;

  position: relative;
  color: var(--color-text-card);
}

.card-section-contact {
  background: var(--color-background);
}

.card-section-contact-body {
  background: var(--color-background-body-contact);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
